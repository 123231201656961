import React from "react";
import {Grid, Typography, Chip, Box, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {DeleteOutlined, OpenInNewOutlined} from "@mui/icons-material";
import * as yup from "yup";
import {useFormContext} from "react-hook-form";
import {populateValues} from "../../../handlers";
import {Field, ControlledTextField, ControlledAutocomplete, ControlledLabel, IconButton} from "../../generics/inputs";
import {ViewMode} from "../../../types";

interface PropertyDocumentsProps{
    fields:Field[]
    index:number
    viewMode:ViewMode
    documents:string[]
    onDelete:(args:React.MouseEvent) => void
}

export const Fields:Field[] = [
    {
        key: "label",
        label: "Document",
        yup: yup.string().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: false,
            fieldLabel: true,
            tooltip: true,
        },
        exportOptions: {
            label: "Property_Property Document",
            order: 1,
        },
    },
    {
        key: "type",
        label: "Type",
        yup: yup.string(),
        type: "label",
        labelOptions: {
            default: "Document Type",
            tooltip: true,
        },
        exportOptions: {
            label: "Property_Document Type",
            order: 2,
        },
    },
    {
        key: "link",
        label: "Link to Document",
        yup: yup.string().url("Invalid url.").required("Required"),
        type: "textfield",
        textfieldOptions: {
            readOnly: false,
            fieldLabel: true,
        },
        exportOptions: {
            label: "Property_Link To Document",
            order: 3,
        },
    },
];

/**
 * PropertyDocuments
 * @param {PropertyDocumentsProps} props
 * @return {React.ReactElement}
 */
function PropertyDocuments(props:PropertyDocumentsProps):React.ReactElement {
    const methods=useFormContext();
    // set field options
    populateValues(props.documents, props.fields, "label");
    // set link onClick
    const link=props.fields.find((i:Field) => i.key.split(".")?.[2]==="link");
    if (link?.textfieldOptions) {
        link.textfieldOptions.endAdornment={
            component: IconButton,
            onClick: (value:string) => (args:React.MouseEvent) => { if (value) window.open(value); },
            children: OpenInNewOutlined,
        };
    }

    // breakpoints
    const breaks=[
        {xs: 0.5, sm: 0.5, md: 1.0, lg: 1.0, xl: 0.6}, // #
        {xs: 4.0, sm: 4.5, md: 4.0, lg: 4.0, xl: 3.5}, // Document
        {xs: 2.6, sm: 3.1, md: 3.0, lg: 2.6, xl: 2.1}, // Type
        {xs: 4.0, sm: 3.0, md: 3.0, lg: 3.4, xl: 5.0}, // Link
        {xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5, xl: 0.5}, // Delete
    ];
    // resolve header
    const header:React.ReactElement[]=[
        <Grid item {...breaks[0]} key={`${props.index}-index`}><Typography variant="body1">#</Typography></Grid>,
    ];
    // iterate over fields headers
    props.fields.forEach((field:Field, index:number) => {
        const isLink = index === 2;
        header.push(
            // Note: "!important" is used to override spacing set by Grid container
            <Grid item key={field.key} {...breaks[index+1]} sx={isLink?{paddingLeft: {xs: "0px!important", lg: "32px!important"}, whiteSpace: {xs: "nowrap", lg: ""}}:{}}><Typography variant="body1">{field.label}</Typography></Grid>,
        );
    });

    // pushing delete placeholder
    header.push(<Grid item {...breaks[4]} key={`${props.index}-delete`} />);
    return (
        <Box>
            {props.index===0 && (
                <Grid sx={{marginLeft: "-20px", marginBottom: "20px"}} wrap="nowrap" container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">{header}</Grid>
            )}
            <Grid container spacing={{xs: 1, lg: 3}} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item {...breaks[0]} sx={{marginTop: "10px"}}><Chip label={props.index+1} /></Grid>
                <Grid item {...breaks[1]}><ControlledAutocomplete field={props.fields[0]} control={methods.control} errors={methods.formState.errors} /></Grid>
                <Grid item {...breaks[2]} sx={{marginTop: "10px"}}><ControlledLabel field={props.fields[1]} control={methods.control} /></Grid>
                <Grid item {...breaks[3]}><ControlledTextField field={props.fields[2]} control={methods.control} errors={methods.formState.errors} /></Grid>
                <Grid item {...breaks[4]} sx={{marginTop: "6px"}}><IconButton disabled={props.viewMode==="VIEW_MODE"} onClick={props.onDelete}><DeleteOutlined /></IconButton></Grid>
            </Grid>
        </Box>
    );
}

export default PropertyDocuments;

import React from "react";
import {Grid, IconButton} from "@mui/material";
import * as yup from "yup";
import {useFormContext} from "react-hook-form";
import {ControlledSwitch, ControlledTextField, Field} from "../../generics/inputs";

interface ControlFunctionsProps{
    fields:Field[]
}

export const Fields:Field[] = [
    // ------ section 1
    {
        key: "control_setpoints_currently_adjustable",
        label: "Are setpoints currently adjustable?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Are setpoints currently adjustable?",
            order: 14,
        },
    },
    {
        key: "schedules_currently_adjustable",
        label: "Are schedules currently adjustable?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Are schedules currently adjustable?",
            order: 15,
        },
    },
    {
        key: "control_has_overrides",
        label: "Are there any overrides?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Are there any overrides?",
            order: 16,
        },
    },
    {
        key: "control_can_change_remove_overrides",
        label: "Can you change or remove overrides?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Can you change or remove overrides?",
            order: 17,
        },
    },
    // ------ section 2
    {
        key: "are_zone_temperatures_viewable",
        label: "Are zone temperatures viewable on the BAS?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
    },
    {
        key: "can_set_zone_temperatures",
        label: "Are you able to set zone temperature setpoints?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
    },
    {
        key: "can_schedule_zone_temperature_setbacks",
        label: "Are you able to schedule zone temperature setbacks?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
    },
    {
        key: "core_zone_temperature_setback_cooling",
        label: "Typical core zone temperature setback – cooling season",
        yup: yup
            .number()
            .typeError("Invalid Number")
            .nullable()
            .transform((_, val) => (val!==""?Number(val):null)),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    {
        key: "perimeter_zone_temperature_setback_cooling",
        label: "Typical perimeter zone temperature setback – cooling season",
        yup: yup
            .number()
            .typeError("Invalid Number")
            .nullable()
            .transform((_, val) => (val!==""?Number(val):null)),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    {
        key: "core_zone_temperature_setback_heating",
        label: "Typical core zone temperature setback – heating season",
        yup: yup
            .number()
            .typeError("Invalid Number")
            .nullable()
            .transform((_, val) => (val!==""?Number(val):null)),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    {
        key: "perimeter_zone_temperature_setback_heating",
        label: "Typical perimeter zone temperature setback – heating season",
        yup: yup
            .number()
            .typeError("Invalid Number")
            .nullable()
            .transform((_, val) => (val!==""?Number(val):null)),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    {
        key: "zone_temperature_notes",
        label: "Notes",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 3000,
            textArea: {
                multiline: true,
                rows: 42,
            },
        },
        exportOptions: {
            label: "BAS_Control Function Notes",
            order: 34,
        },
    },
];

/**
 * ControlFunctions
 * @param {ControlFunctionsProps} props
 * @return {React.ReactElement}
 */
function ControlFunctions(props:ControlFunctionsProps):React.ReactElement {
    const methods=useFormContext();

    [
        "core_zone_temperature_setback_cooling",
        "perimeter_zone_temperature_setback_cooling",
        "core_zone_temperature_setback_heating",
        "perimeter_zone_temperature_setback_heating",
    ].forEach((key:string) => {
        const field=props.fields.find((f:Field) => f.key===key);
        if (field && field.textfieldOptions) {
            field.textfieldOptions.endAdornment={
                component: IconButton,
                children: "°F",
            };
        }
    });

    /**
     * constrcutLayout
     * @param {Field[]} fields
     * @return {React.ReactElement}
     */
    const constrcutLayout=(fields:Field[]):React.ReactElement => (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {/* column 1 */}
            <Grid container spacing={2.84} item xs={12} sm={12} md={12} lg={6} xl={6}>
                {fields.slice(0, fields.length-1).map((field:Field) => (
                    <Grid key={field.key} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {field.type==="textfield" && <ControlledTextField field={field} control={methods.control} errors={methods.formState.errors} />}
                        {field.type==="switch" && <ControlledSwitch field={field} control={methods.control} errors={methods.formState.errors} />}
                    </Grid>
                ))}
            </Grid>
            {/* column 2 */}
            <Grid container spacing={3} item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><ControlledTextField field={fields[fields.length-1]} control={methods.control} errors={methods.formState.errors} /></Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{constrcutLayout(props.fields.slice(0, props.fields.length))}</Grid>
        </Grid>
    );
}

export default ControlFunctions;

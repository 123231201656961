import {
    ControlFunctionsFields,
    EnertracDetailsFields,
    OperatorDetailsFields,
    OverviewFields,
    PropertyDocumentsFields,
    RealTimeMetersFields,
    PropertyDetailsFields,
    RemoteAccessFields,
    TrendingFields,
    BaseFields,
    EQUIPMENTS,
} from "../components/forms";
import {Component, EquipmentType} from "../types";

/**
* flattenFields
* @param {any} fields
* @return {any}
*/
const flattenFields = (fields:any):any => fields
    .flatMap((field:any):any => field.filter((x:any) => x.exportOptions))
    .sort((a:any, b:any) => a.exportOptions.order-b.exportOptions.order);

/**
* fetchKeys
* @param {string[]} categoriesArray
* @return {any}
*/
const fetchKeys = ():any => {
    const keysObject:Record<any, any> = {components: {}};

    EQUIPMENTS.forEach((field:EquipmentType) => {
        // add category if not exist
        if (!keysObject[field.category]) keysObject[field.category] = {keys: [], fields: []};
        // pushing keys and fields
        if (!keysObject[field.category].keys.includes(field.key)) {
            keysObject[field.category].keys.push(field.key);
            keysObject[field.category].fields.push(field.systemFields);
        }
        // add to components
        if (field.component) {
            if (Array.isArray(field.component)) {
                field.component.forEach((component:Component) => { keysObject.components[component.key]=field.key; });
            } else keysObject.components[field.component.key] = field.key;
        }
    });
    return keysObject;
};

const KEYS = fetchKeys();

// keys and related systems
const SYSTEM_KEYS = [...KEYS.heating.keys, ...KEYS.cooling.keys, ...KEYS.cooling.keys, ...KEYS.airhandling.keys, ...KEYS.terminal.keys];

// Shared custom fields for systems/equipments
const customSystemFields = [
    {key: "bas_software", label: "BAS_Software", exportOptions: {order: 2}},
    {key: "bas_version", label: "BAS_Version", exportOptions: {order: 3}},
    {key: "category", label: "Category", exportOptions: {order: 4}},
    {key: "system", label: "System", exportOptions: {order: 6}},
];

// Filtered, Sorted, and Flattened Fields Arrays
// used for Key/Label resolutions and ordering.
const PROPERTY_FIELDS = flattenFields([PropertyDetailsFields, EnertracDetailsFields, OperatorDetailsFields]);
const REAL_TIME_METER_FIELDS = flattenFields([RealTimeMetersFields]);
const DOCUMENTS_FIELDS = flattenFields([PropertyDocumentsFields]);
const BAS_FIELDS = flattenFields([OverviewFields, RemoteAccessFields, ControlFunctionsFields, TrendingFields]);
const HEATING_FIELDS = flattenFields([...KEYS.heating.fields, ...[customSystemFields]]);
const COOLING_FIELDS = flattenFields([...KEYS.cooling.fields, ...[customSystemFields]]);
const AIR_HANDLING_FIELDS = flattenFields([...KEYS.airhandling.fields, ...[customSystemFields]]);
const TERMINAL_UNITS_FIELDS = flattenFields([...KEYS.terminal.fields, ...[customSystemFields]]);
const COMPONENT_FIELDS = flattenFields([[...BaseFields, ...[
    {key: "bas_software", label: "BAS_Software", exportOptions: {order: 2}},
    {key: "systemId", label: "System ID/Tag", exportOptions: {order: 4}},
    {key: "system", label: "System", exportOptions: {order: 5}},
    {key: "componentType", label: "Component", exportOptions: {order: 7}},
]]]);

export {
    KEYS,
    REAL_TIME_METER_FIELDS,
    DOCUMENTS_FIELDS,
    PROPERTY_FIELDS,
    BAS_FIELDS,
    HEATING_FIELDS,
    COOLING_FIELDS,
    AIR_HANDLING_FIELDS,
    TERMINAL_UNITS_FIELDS,
    COMPONENT_FIELDS,
    SYSTEM_KEYS,
};

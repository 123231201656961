import React from "react";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {UseFormReturn} from "react-hook-form";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {populateValues, resolveLabels, cleanValue, resolvePoints} from "../../../../handlers";
import {ControlledAutocomplete, ControlledLabelSelector, ControlledTextField, Field} from "../../../generics/inputs";
import {OPERATION_TIMES, BAS_ARCHIVED_LABEL} from "../../../../config";
import {OptionType} from "../../../../types";
import {COMMON_FIELDS} from "../../common/Fields";

interface DhwProps{
    fields: Field[]
    bases: string[]
    form: UseFormReturn
}

/**
 * toApi
 * @param {any} values
 * @param {AxiosResponse} bases
 */
export const toApi = (values: any, bases: AxiosResponse): any => {
    const KEYS=[
        {key: "bas_version", labels: undefined},
        {key: "operation_times", labels: OPERATION_TIMES},
    ];
    // remove UI keys
    const payload:any=_.omit(values, KEYS.slice(0, 2).map((i:any) => i.key));
    // resolve key/id => label(s)
    payload.bas_id=bases.data.results.find((r:any) => r.software===values.bas_version.primary && r.version===values.bas_version.secondary)?.id;
    KEYS.slice(1).forEach((i:any) => resolveLabels(values[i.key], payload, i.labels));
    payload.sequence_of_operations=values.sequence_of_operations.length===0?null:values.sequence_of_operations;
    payload.points={};
    return payload;
};

/**
 * fromApi
 * @param {AxiosResponse} record
 * @param {AxiosResponse} bases
 * @param {Field[]} fields
 */
export const fromApi = (record: AxiosResponse, bases: AxiosResponse, fields:Field[]): any => ({
    ...fields?.reduce(
        (a:any, v:any) => {
            const {data}:any=record;
            const bas=bases?.data.results.find((i:any) => i.id===data.bas_id);
            // NOTE: aux func to resolve incomming api keys
            const resolveKey=(labels:any):any => labels.filter((i:any) => ((i.key in data) && data[i.key]===true)).map((i:any) => i.label); // eslint-disable-line require-jsdoc

            const KEYS=[
                {key: "bas_version", labels: {primary: bas?.software||"None", secondary: bas?.version||"", ...bas?.archived===true && {tertiary: BAS_ARCHIVED_LABEL}} as OptionType},
                {key: "operation_times", labels: resolveKey(OPERATION_TIMES)},
                {key: "points", labels: resolvePoints(data.points)},
            ];

            return ({
                ...a,
                [v.key]: KEYS.find((i:any) => i.key===v.key)?.labels||cleanValue(record?.data?.[v.key]),
            });
        },
        {},
    ),
});

export const Fields: Field[] = [
    COMMON_FIELDS.name,
    COMMON_FIELDS.sequence_of_operations,
    COMMON_FIELDS.tag_id,
    COMMON_FIELDS.bas_version,
    {
        key: "heater_model",
        label: "Heater Model",
        yup: yup.mixed(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {order: 9},
    },
    COMMON_FIELDS.operation_times,
    COMMON_FIELDS.notes,
];

/**
 * Dhw
 * @return {React.ReactElement}
 */
function Dhw(props: DhwProps): React.ReactElement {
    [
        {values: props.bases, key: "bas_version"},
        {values: OPERATION_TIMES, key: "operation_times"},
    ].forEach((item:any) => populateValues(item.key==="bas_version"?item.values:item.values.map((i:any) => i.label), props.fields, item.key));

    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {props.fields.slice(0, 2).map((field:Field) => (
                <Grid key={field.key} item xs={12} sm={12} md={12} lg={6} xl={6}>
                    {field.type==="textfield" && <ControlledTextField field={field} control={props.form.control} errors={props.form.formState.errors} />}
                </Grid>
            ))}
            {/* 2-column layout */}
            <Grid item container spacing={4}>
                <Grid item container xs={12} sm={12} md={12} lg={6} xl={6} spacing={4}>
                    {props.fields.slice(2, props.fields.length-1).map((field: Field) => (
                        <Grid key={field.key} item xs={12}>
                            {field.type==="textfield" && <ControlledTextField field={field} control={props.form.control} errors={props.form.formState.errors} />}
                            {field.type==="autocomplete" && <ControlledAutocomplete field={field} control={props.form.control} errors={props.form.formState.errors} />}
                            {field.type==="labelselector" && <ControlledLabelSelector field={field} control={props.form.control} />}
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}><ControlledTextField field={props.fields[props.fields.length-1]} control={props.form.control} errors={props.form.formState.errors} /></Grid>
            </Grid>
        </Grid>
    );
}

export default Dhw;

import React from "react";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {UseFormReturn} from "react-hook-form";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {populateValues, resolveLabels, resolveLabel, resolvePoints, cleanValue, transformPoints} from "../../../../handlers";
import {ControlledAutocomplete, ControlledLabelSelector, ControlledTextField, Field} from "../../../generics/inputs";
import PointsGrid from "../../../layout/PointsGrid";
import {OPERATION_TIMES, BAS_ARCHIVED_LABEL, AC_CHILLER_TYPE, PIPING_CONFIG, COMPRESSOR_FAN_SPEED} from "../../../../config";
import {OptionType} from "../../../../types";
import {Fields as PointFields} from "../../common/Point";
import {COMMON_FIELDS} from "../../common/Fields";

interface ACChillerProps {
    fields:Field[]
    bases:string[]
    form:UseFormReturn
    values?:AxiosResponse|null
}

/**
 * toApi
 * @param {any} values
 * @param {AxiosResponse} bases
 */
export const toApi = (values: any, bases: AxiosResponse): any => {
    const KEYS=[
        {key: "bas_version", labels: undefined},
        {key: "operation_times", labels: OPERATION_TIMES},
        {key: "chiller_type", labels: AC_CHILLER_TYPE},
        {key: "piping_config", labels: PIPING_CONFIG},
        {key: "compressor_speed", labels: COMPRESSOR_FAN_SPEED},
    ];
    // remove UI keys
    const payload:any=_.omit(values, KEYS.slice(0, 2).map((i:any) => i.key));
    // resolve key/id => label(s)
    payload.bas_id=bases.data.results.find((r:any) => r.software===values.bas_version.primary && r.version===values.bas_version.secondary)?.id;
    KEYS.slice(1, 2).forEach((i:any) => resolveLabels(values[i.key], payload, i.labels));
    KEYS.slice(2).forEach((i:any) => { payload[i.key]=resolveLabel(i.labels, values[i.key]); });
    payload.sequence_of_operations=values.sequence_of_operations.length===0?null:values.sequence_of_operations;
    payload.points=transformPoints(values.points);
    return payload;
};

/**
 * fromApi
 * @param {AxiosResponse} record
 * @param {AxiosResponse} bases
 * @param {Field[]} fields
 */
export const fromApi = (record: AxiosResponse, bases: AxiosResponse, fields:Field[]): any => ({
    ...fields?.reduce(
        (a:any, v:any) => {
            const {data}:any=record;
            const bas=bases?.data.results.find((i:any) => i.id===data.bas_id);
            // NOTE: aux func to resolve incomming api keys
            const resolveKey=(labels:any):any => labels.filter((i:any) => ((i.key in data) && data[i.key]===true)).map((i:any) => i.label); // eslint-disable-line require-jsdoc
            const resolveKeyOverride=(labels:any):any => labels.find((i:any) => i.key===data[v.key])?.label; // eslint-disable-line require-jsdoc

            const KEYS=[
                {key: "bas_version", labels: {primary: bas?.software||"None", secondary: bas?.version||"", ...bas?.archived===true && {tertiary: BAS_ARCHIVED_LABEL}} as OptionType},
                {key: "operation_times", labels: resolveKey(OPERATION_TIMES)},
                {key: "chiller_type", labels: resolveKeyOverride(AC_CHILLER_TYPE)},
                {key: "piping_config", labels: resolveKeyOverride(PIPING_CONFIG)},
                {key: "compressor_speed", labels: resolveKeyOverride(COMPRESSOR_FAN_SPEED)},
                {key: "points", labels: resolvePoints(data.points)},
            ];

            return ({
                ...a,
                [v.key]: KEYS.find((i:any) => i.key===v.key)?.labels||cleanValue(record?.data?.[v.key]),
            });
        },
        {},
    ),
});

export const Fields: Field[] = [
    {
        ...COMMON_FIELDS.name,
        exportOptions: {order: 7},
    },
    COMMON_FIELDS.sequence_of_operations,
    {
        ...COMMON_FIELDS.tag_id,
        exportOptions: {order: 5},
    },
    {
        key: "n_compressors",
        label: "# of Compressors",
        yup: yup
            .number()
            .integer("Must be Positive Integer.")
            .moreThan(-1, "Must be Positive Integer.")
            .typeError("Invalid Number")
            .transform((__, val) => (val!==""?Number(val):null))
            .nullable(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {order: 13},
    },
    COMMON_FIELDS.bas_version,
    {
        key: "compressor_speed",
        label: "Compressor Speed",
        yup: yup.mixed().nullable(),
        type: "labelselector",
        labelselectorOptions: {
            options: [],
            disabled: true,
            multi: false,
        },
        exportOptions: {order: 14},
    },
    {
        key: "model",
        label: "Chiller Model",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {order: 8},
    },
    {
        key: "chilled_water_serves",
        label: "Chilled Water Serves",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {order: 11},
    },
    {
        key: "capacity",
        label: "Chiller Capacity (tons)",
        yup: yup
            .number()
            .integer("Must be Positive Integer.")
            .moreThan(-1, "Must be Positive Integer.")
            .typeError("Invalid Number")
            .transform((__, val) => (val!==""?Number(val):null))
            .nullable(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {order: 10},
    },
    {
        key: "piping_config",
        label: "Piping Configuration",
        yup: yup.mixed().nullable(),
        type: "labelselector",
        labelselectorOptions: {
            options: [],
            disabled: true,
            multi: false,
        },
        exportOptions: {order: 16},
    },
    {
        key: "chiller_type",
        label: "Chiller Type",
        yup: yup.mixed().nullable(),
        type: "labelselector",
        labelselectorOptions: {
            options: [],
            disabled: true,
            multi: false,
        },
        exportOptions: {order: 9},
    },
    COMMON_FIELDS.operation_times,
    COMMON_FIELDS.notes,
    {
        key: "points",
        label: "Points",
        yup: yup.array().of(yup.object().shape(PointFields.reduce((a:any, v:any) => ({...a, [v.key]: v.yup}), {}))),
        type: "points",
        pointsOptions: {
            disabled: true,
            options: [
                {key: "outside_air_temperature", label: "Chiller Outside Air Temperature (°F)"},
                {key: "run_command", label: "Chiller Run Command"},
                {key: "status", label: "Chiller Status"},
                {key: "wet_bulb_outside_air_temperature", label: "Chiller Wet-Bulb Outside Air Temperature (°F)"},
            ],
        },
    },
];

/**
 * ACChiller
 * @return {React.ReactElement}
 */
function ACChiller(props: ACChillerProps):React.ReactElement {
    [
        {values: props.bases, key: "bas_version"},
        {values: OPERATION_TIMES, key: "operation_times"},
        {values: AC_CHILLER_TYPE, key: "chiller_type"},
        {values: PIPING_CONFIG, key: "piping_config"},
        {values: COMPRESSOR_FAN_SPEED, key: "compressor_speed"},
    ].forEach((item:any) => populateValues(item.key==="bas_version"?item.values:item.values.map((i:any) => i.label), props.fields, item.key));

    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {props.fields.slice(0, 8).map((field:Field) => (
                <Grid key={field.key} item xs={12} sm={12} md={12} lg={6} xl={6}>
                    {field.type==="textfield" && <ControlledTextField field={field} control={props.form.control} errors={props.form.formState.errors} />}
                    {field.type==="labelselector" && <ControlledLabelSelector field={field} control={props.form.control} />}
                    {field.type==="autocomplete" && <ControlledAutocomplete field={field} control={props.form.control} errors={props.form.formState.errors} />}
                </Grid>
            ))}
            {/* 2-column layout */}
            <Grid item container spacing={4}>
                <Grid item container xs={12} sm={12} md={12} lg={6} xl={6} spacing={4}>
                    {props.fields.slice(8, props.fields.length-2).map((field: Field) => (
                        <Grid key={field.key} item xs={12}>
                            {field.type==="textfield" && <ControlledTextField field={field} control={props.form.control} errors={props.form.formState.errors} />}
                            {field.type==="labelselector" && <ControlledLabelSelector field={field} control={props.form.control} />}
                            {field.type==="autocomplete" && <ControlledAutocomplete field={field} control={props.form.control} errors={props.form.formState.errors} />}
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}><ControlledTextField field={props.fields[props.fields.length-2]} control={props.form.control} errors={props.form.formState.errors} /></Grid>
            </Grid>
            {/* Points */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><PointsGrid values={props.values} field={props.fields[props.fields.length-1]} form={props.form} /></Grid>
        </Grid>
    );
}

ACChiller.defaultProps={
    values: undefined,
};

export default ACChiller;

import React from "react";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {UseFormReturn} from "react-hook-form";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {cleanValue, populateValues, resolveLabels, resolvePoints, transformPoints} from "../../../../handlers";
import {ControlledAutocomplete, ControlledLabelSelector, ControlledTextField, Field, ControlledSwitch} from "../../../generics/inputs";
import PointsGrid from "../../../layout/PointsGrid";
import {AHU_TYPE, OPERATION_TIMES, BAS_ARCHIVED_LABEL} from "../../../../config";
import {Fields as PointFields} from "../../common/Point";
import {COMMON_FIELDS} from "../../common/Fields";
import {OptionType} from "../../../../types";

interface AvbProps{
    fields:Field[]
    bases:string[]
    form:UseFormReturn
    values?:AxiosResponse|null
}

export const Fields:Field[] = [
    {...COMMON_FIELDS.name, exportOptions: {order: 7}},
    COMMON_FIELDS.sequence_of_operations,
    {...COMMON_FIELDS.tag_id, exportOptions: {order: 5}},
    {
        key: "zones_served",
        label: "Zones Served",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {order: 8},
    },
    {...COMMON_FIELDS.bas_version},
    {
        key: "reheat",
        label: "Reheat?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {order: 11},
    },
    {
        key: "fan_powered",
        label: "Fan Powered?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {order: 10},
    },
    {...COMMON_FIELDS.operation_times, exportOptions: {order: 12}},
    {...COMMON_FIELDS.notes, exportOptions: {order: 13}},
    {
        key: "points",
        label: "Points",
        yup: yup.array().of(yup.object().shape(PointFields.reduce((a:any, v:any) => ({...a, [v.key]: v.yup}), {}))),
        type: "points",
        pointsOptions: {
            disabled: true,
            options: [
                "cooling_mode",
                "heating_mode",
                "reheat_valve_signal",
                "schedule",
                "static_pressure",
                "zone_temperature",
            ],
        },
    },
];

export const VavbFields:Field[] = [
    ...Fields.map((field:Field) => (
        field.key==="points"
            ?{
                ...field,
                pointsOptions: {
                    ...field.pointsOptions,
                    options: [...(field.pointsOptions?.options || []),
                        {key: "damper_position", label: "VAV Damper Position (%)"},
                        {key: "discharge_air_temperature", label: "VAV Discharge Air Temperature (°F)"}]}}
            :field)),
];

export const CavbFields:Field[] = [
    ...Fields.map((field:Field) => (
        field.key==="points"
            ?{
                ...field,
                pointsOptions: {
                    ...field.pointsOptions,
                    options: [...(field.pointsOptions?.options || []),
                        {key: "damper_position", label: "CAV Damper Position (%)"},
                        {key: "discharge_air_temperature", label: "CAV Discharge Air Temperature (°F)"}]}}
            :field)),
];

/**
 * toApi
 * @param {any} values
 * @param {AxiosResponse} bases
 */
export const toApi = (values:any, bases:AxiosResponse):any => {
    const KEYS=[
        {key: "bas_version", labels: undefined},
        {key: "operation_times", labels: OPERATION_TIMES},
    ];
        // remove UI keys
    const payload:any=_.omit(values, KEYS.map((i:any) => i.key));
    // resolve key/id => label(s)
    payload.bas_id=bases.data.results.find((r:any) => r.software===values.bas_version.primary && r.version===values.bas_version.secondary)?.id;
    KEYS.slice(1).forEach((i:any) => resolveLabels(values[i.key], payload, i.labels));
    payload.sequence_of_operations=values.sequence_of_operations.length===0?null:values.sequence_of_operations;
    payload.points=transformPoints(values.points);
    return payload;
};

/**
 * fromApi
 * @param {AxiosResponse} record
 * @param {AxiosResponse} bases
 * @param {Field[]} fields
 */
export const fromApi = (record:AxiosResponse, bases:AxiosResponse, fields:Field[]):any => ({
    ...fields?.reduce(
        (a:any, v:any) => {
            const {data}:any=record;
            const bas=bases?.data.results.find((i:any) => i.id===data.bas_id);
            // NOTE: aux func to resolve incomming api keys
            const resolveKey=(labels:any):any => labels.filter((i:any) => ((i.key in data) && data[i.key]===true)).map((i:any) => i.label); // eslint-disable-line require-jsdoc

            const KEYS=[
                {key: "bas_version", labels: {primary: bas?.software||"None", secondary: bas?.version||"", ...bas?.archived===true && {tertiary: BAS_ARCHIVED_LABEL}} as OptionType},
                {key: "operation_times", labels: resolveKey(OPERATION_TIMES)},
                {key: "points", labels: resolvePoints(data.points)},
            ];

            return ({
                ...a,
                [v.key]: KEYS.find((i:any) => i.key===v.key)?.labels||cleanValue(record?.data?.[v.key]),
            });
        },
        {},
    ),
});

/**
 * Avb
 * @return {React.ReactElement}
 */
function Avb(props:AvbProps):React.ReactElement {
    // set field options
    [
        {values: props.bases, key: "bas_version"},
        {values: AHU_TYPE, key: "type"},
        {values: OPERATION_TIMES, key: "operation_times"},
    ].forEach((item:any) => populateValues(item.key==="bas_version"?item.values:item.values.map((i:any) => i.label), props.fields, item.key));
    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {props.fields.slice(0, props.fields.length-6).map((field:Field) => (
                <Grid key={field.key} item xs={12} sm={12} md={12} lg={6} xl={6}>
                    {field.type==="textfield" && <ControlledTextField field={field} control={props.form.control} errors={props.form.formState.errors} />}
                    {field.type==="labelselector" && <ControlledLabelSelector field={field} control={props.form.control} />}
                    {field.type==="autocomplete" && <ControlledAutocomplete field={field} control={props.form.control} errors={props.form.formState.errors} />}
                </Grid>
            ))}
            {/* 2-column layout */}
            <Grid item container spacing={4}>
                <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}>
                    {props.fields.slice(4, props.fields.length-2).map((field:Field) => (
                        <Grid item key={field.key} xs={12}>
                            {field.type==="autocomplete" && <ControlledAutocomplete field={field} control={props.form.control} errors={props.form.formState.errors} />}
                            {field.type==="labelselector" && <ControlledLabelSelector field={field} control={props.form.control} />}
                            {field.type==="switch" && <ControlledSwitch field={field} control={props.form.control} />}
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}><ControlledTextField field={props.fields[props.fields.length-2]} control={props.form.control} errors={props.form.formState.errors} /></Grid>
            </Grid>
            {/* Points */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><PointsGrid values={props.values} field={props.fields[props.fields.length-1]} form={props.form} /></Grid>
        </Grid>
    );
}

Avb.defaultProps={
    values: undefined,
};

export default Avb;
